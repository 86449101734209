import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    // The background images overlap if the page is < about 1500px high
    // We hide the last background image by default and only show it when the page is long enough
    var body = document.body,
    html = document.documentElement;
    var height = Math.max( body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight );

    if (height < 1500 ) return
    this.element.classList.remove("hidden")
  }


}
