import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   connect() {
//     // console.log("Connected to the nav bar", this.element)
//   }
// }



export default class extends Controller {
  static targets = ['menu', 'button', 'subMenu', 'input']
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.visibleClass = this.data.get('visibleClass') || null
    this.invisibleClass = this.data.get('invisibleClass') || null
    this.activeClass = this.data.get('activeClass') || null
    this.enteringClass = this.data.get('enteringClass') || null
    this.leavingClass = this.data.get('leavingClass') || null

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("keydown", this._onMenuButtonKeydown)
    }

    this.element.setAttribute("aria-haspopup", "true")
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener("keydown", this._onMenuButtonKeydown)
    }
  }

  // This prevents the focus ring from showing up if the element was clicked.
  // It will still show up for accessibility if the element is selected by tabbing
  preventFocus(e) {
    e.preventDefault()
  }

  preventClose(e) {
    e.stopPropagation()
    this.openValue = true
  }

  toggle(e) {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show(cb) {
    this.menuTarget.classList.remove(this.toggleClass)
    // This is to fix an issue where when a dropdown is in a table that has overflow-x-scroll, we need to position the menu absolutely so it pops out
    // of the overflow.  You can't do overflow-x-scroll overflow-y-visible:
    // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    if (this.hasButtonTarget && this.menuTarget.closest(".overflow-x-auto")) {
      let rect = this.buttonTarget.getBoundingClientRect()
      this.menuTarget.style.right = `${window.innerWidth - rect.right}px`
    }
    setTimeout(
      (() => {
        this.element.setAttribute("aria-expanded", "true")
        this._enteringClassList[0].forEach(
          (klass => {
            this.menuTarget.classList.add(klass)
          }).bind(this),
        )

        this._activeClassList[0].forEach(klass => this.activeTarget.classList.add(klass))
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        setTimeout(
          (() => {
            this._enteringClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
          }).bind(this),
          this.enterTimeout[0],
        )

        if (typeof cb == 'function') cb()
      }).bind(this),
    )
  }

  _hide(cb) {
    // This triggers the .back() method on any sub menu controllers - the sub-menu controller is added to the element["sub-menu"] attribute
    // in the sub-menu controller.  This is a good way to trigger a method on a different controller
    this.subMenuTargets.filter(e => e["sub-menu"]).forEach(e => e["sub-menu"].back())
    setTimeout(
      (() => {
        this.element.setAttribute("aria-expanded", "false")
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        this._visibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._activeClassList[0].forEach(klass => this.activeTarget.classList.remove(klass))
        this._leavingClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        if (typeof cb == 'function') cb()
        setTimeout(
          (() => {
            this._leavingClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))

            this.menuTarget.classList.add(this.toggleClass)
          }).bind(this),
          this.leaveTimeout[0],
        )
      }).bind(this),
    )
  }

  filter(e) {
    let val = e.target.value.toLowerCase()
    this.element.querySelectorAll("li").forEach(e => {
      if(val.length == 0 || e.textContent.toLowerCase().includes(val)) {
        e.classList.remove("hidden")
      } else {
        e.classList.add("hidden")
      }
    })
  }

  keydown = (e) => {
    console.log(e)
    if(e.key === "ArrowDown" || e.keyCode === 40) {
      this.show()
      this.progressActive()
    } else if (e.key === "ArrowUp" || e.keyCode === 38) {
      this.show()
      this.regressActive()
    } else if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault()
      e.stopPropagation()
      this.makeChosen(this.currentActive())
    } else if (e.key === "Escape" || e.keyCode == 27) {
      this.openValue = false
    }
  }

  regressActive() {
    if(this.currentActive() === null) {
      let li = this.element.querySelector("li")
      this.setActiveClasses(li)
    } else {
      let current = this.currentActive()
      this.setNotActiveClasses(current)
      let next = current.previousElementSibling
      this.setActiveClasses(next)
    }
  }

  progressActive() {
    if(this.currentActive() === null) {
      let li = this.element.querySelector("li")
      this.setActiveClasses(li)
    } else {
      let current = this.currentActive()
      this.setNotActiveClasses(current)
      let next = current.nextElementSibling
      this.setActiveClasses(next)
    }
  }

  currentActive() {
    let element = this.element.querySelector("li[data-active='true']")
    return element.classList.contains("hidden")
      ? null
      : element
  }

  choose(e) {
    let li = e.target.closest("li")
    this.makeChosen(li)
  }

  makeChosen(li){
    this.inputTarget.value = li.textContent.trim()
    this.element.querySelectorAll("li[data-selected='true']").forEach(li => this.unselect(li))
    li.querySelector("svg").classList.remove("hidden")
    li.querySelector("span.block").classList.add("font-semibold")
    li.dataset.selected = true
    this.openValue = false
  }



  unselect(element) {
    element.querySelector("svg").classList.add("hidden")
    element.querySelector("span.block").classList.remove("font-semibold")
    element.dataset.selected = false
  }

  makeActive(e) {
    let li = e.target.closest('li')
    this.setActiveClasses(li)
  }

  setActiveClasses(li) {
    li.dataset.active = true
    li.classList.add("text-white", "bg-indigo-600")
    li.classList.remove("text-gray-900")
    let svg = li.querySelector("svg")
    svg.classList.remove("text-indigo-600")
    svg.classList.add("text-white")

  }

  makeNotActive(e) {
    let li = e.target.closest('li')
    this.setNotActiveClasses(li)
  }

  setNotActiveClasses(li) {
    li.dataset.active = false
    li.classList.remove("text-white", "bg-indigo-600")
    li.classList.add("text-gray-900")
    let svg = li.querySelector("svg")
    svg.classList.add("text-indigo-600")
    svg.classList.remove("text-white")
  }

  _onMenuButtonKeydown = event => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault()
        this.toggle()
    }
  }

  show(){
     this.openValue = true;
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  get activeTarget() {
    return this.data.has('activeTarget')
      ? document.querySelector(this.data.get('activeTarget'))
      : this.element
  }

  get _activeClassList() {
    return !this.activeClass
      ? [['opacity-100'], []]
      : this.activeClass.split(',').map(classList => classList.split(' '))
  }

  get _visibleClassList() {
    return !this.visibleClass
      ? [['opacity-100', 'scale-100'], []]
      : this.visibleClass.split(',').map(classList => classList.split(' '))
  }

  get _invisibleClassList() {
    return !this.invisibleClass
      ? [['opacity-0', 'scale-95'], []]
      : this.invisibleClass.split(',').map(classList => classList.split(' '))
  }

  get _enteringClassList() {
    return !this.enteringClass
      ? [['ease-out', 'duration-100', 'transform'], []]
      : this.enteringClass.split(',').map(classList => classList.split(' '))
  }

  get _leavingClassList() {
    return !this.leavingClass
      ? [['ease-in', 'duration-100', 'transform'], []]
      : this.leavingClass.split(',').map(classList => classList.split(' '))
  }

  get enterTimeout() {
    let timeout = this.data.get('enterTimeout') || '100,100'
    return timeout.split(',').map(t => parseInt(t))
  }

  get leaveTimeout() {
    let timeout = this.data.get('leaveTimeout') || '100,100'
    return timeout.split(',').map(t => parseInt(t))
  }
}

